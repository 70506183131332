import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import SEO from "../SEO/seo";
import PageHeroBanner from "../PageHeroBanner";
import ButtonLink from "../ButtonLink";
import Section from "../Section";
import AspectRatio from "../AspectRatio";
import BgImage from "../BgImage";
import HomeContainer from "../../containers/HomeContainer";
import Carousel from "../Carousel";
import MarkdownToHtml from "../MarkdownToHtml";
import NewsCard from "../CommonComponents/NewsCard";
import NewsContainer from "../../containers/NewsContainer";
import { titleToPath } from "../../helper/helper";

const HomePage = () => {
  let prevIndex = 0;

  const setSection = (sectionDetails) => {
    const { horizontalCardSection, heroBannerSection } = sectionDetails;
    const { horizontalCard } = horizontalCardSection;

    const theme = useContext(ThemeContext);

    return (
      <>
        <Carousel
          settings={{
            dots: true,
            pauseOnHover: true,
            responsive: [
              {
                breakpoint: 960,
                settings: {
                  // dots: !card,
                  slidesToShow: 1,
                  variableWidth: false,
                },
              },
            ],
            slidesToScroll: 1,
            slidesToShow: 1,
            speed: 500,
          }}
          sx={{
            "& .slick-dots": {
              "& li.slick-active,li": {
                "& button:before": {
                  color: "yellowDark",
                },
                mb: 6,
              },
            },
            "& .slick-prev:before, & .slick-next:before": {
              color: "darkGray",
              display: ["block", null, null, "none"],
            },
            "& .slick-slide": {
              width: [null, null, null, null],
            },
            minHeight: "100vh",
            mt: [
              `-${theme.sizes.heights.header[0]}`,
              null,
              null,
              `-${theme.sizes.heights.header[1]}`,
            ],
            "svg.slider-prev-arrow,svg.slider-next-arrow": {
              display: ["none", null, null, "block"],
            },
            width: "100%",
          }}
        >
          {heroBannerSection &&
            heroBannerSection.map((card) => (
              <PageHeroBanner
                actionButton={card.actionButton}
                bannerImage={card.bannerImage}
                content={{
                  heading: card.heading,
                  text: card.content,
                }}
              />
            ))}
        </Carousel>
        {horizontalCardSection && !horizontalCardSection.hideSection && (
          <Section
            key={`main-section-${0}`}
            sx={{
              bg: "grayBackground",
            }}
          >
            {horizontalCardSection.heading && (
              <MarkdownToHtml
                content={horizontalCardSection.content}
                heading={horizontalCardSection.heading}
                sx={{
                  mb: 5,
                  textAlign: ["center", null, null, "left"],
                }}
              />
            )}
            {horizontalCard &&
              horizontalCard.map((cardItem, index) => {
                const currentIndex =
                  prevIndex > 0 && prevIndex % 2 !== 0 ? 2 : 1;
                const isOdd = currentIndex % 2 !== 0;
                prevIndex = currentIndex;
                return (
                  <Box
                    key={`service-section-${index}`}
                    sx={{
                      "&:not(:last-child)": {
                        mb: [4, null, null, 0],
                      },
                      display: "flex",
                      flexDirection: isOdd ? "" : "row-reverse",
                      flexWrap: "wrap",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        textAlign: [
                          "center",
                          null,
                          null,
                          isOdd ? "left" : "right",
                        ],
                        width: ["100%", null, null, "50%"],
                      }}
                    >
                      <AspectRatio
                        ratio={[[1, 1], null, null, [1, 1]]}
                        sx={{
                          bg: "primarySubtle",
                          width: "50%",
                          ...(isOdd
                            ? theme.designElement.leftBottom
                            : theme.designElement.rightBottom),
                        }}
                      >
                        <Box
                          sx={{
                            backgroundPosition: "center",
                            height: ["80%", null, null, "75%"],
                            my: "auto",
                          }}
                        >
                          <BgImage
                            name={cardItem.cardImage}
                            page="home"
                            sx={{
                              backgroundPosition: "inherit",
                              backgroundSize: "contain",
                            }}
                          />
                        </Box>
                      </AspectRatio>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        fontSize: [1, null, null, 2],
                        justifyContent: "center",
                        pb: [null, null, null, 4],
                        position: "relative",
                        pt: [3, null, null, 4],
                        textAlign: ["center", null, null, "left"],
                        width: ["100%", null, null, "50%"],
                        [isOdd ? "ml" : "mr"]: [null, null, null, "auto"],
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <MarkdownToHtml
                          content={cardItem.content}
                          heading={cardItem.heading}
                          headingSx={{
                            ":before": {
                              bg: "yellowDark",
                              content: "''",
                              height: [null, null, null, null, "1px"],
                              position: "absolute",
                              top: "13.5px",
                              width: "180px",
                              [isOdd ? "left" : "right"]: [
                                null,
                                null,
                                null,
                                "-250px",
                              ],
                            },
                            position: "relative",
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })}
          </Section>
        )}
        {!sectionDetails.hideNewsSection && (
          <Section>
            <Box
              sx={{
                textAlign: ["center", null, null, "left"],
              }}
              variant="markdown.h2"
            >
              News
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                mx: -3,
                textAlign: ["center", null, null, "left"],
              }}
            >
              <NewsContainer>
                {(NewsData, ArticlesData, FilesData) => {
                  const filesInfo = [];
                  FilesData.map((file) => filesInfo.push(file.publicURL));
                  return (
                    <>
                      {ArticlesData.slice(0, 2).map((article) => {
                        const documentPublicURL = filesInfo.find(
                          (publicURL) =>
                            article.document &&
                            publicURL.includes(
                              article.document
                                .split("/")
                                .filter((e) => e !== "")[1]
                            ) &&
                            publicURL
                        );
                        return (
                          !article.hideCard && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexWrap: "wrap",
                                px: 4,
                                py: [3, null, null, 4],
                                width: ["100%", null, "calc(100% / 2)"],
                              }}
                            >
                              <NewsCard
                                cardContent={article.brief}
                                cardDate={article.date}
                                cardTitle={article.title}
                                externalLink={
                                  article.articleType === "externalLink"
                                    ? article.externalLink
                                    : article.articleType === "document" &&
                                      documentPublicURL
                                }
                                url={
                                  article.articleType ===
                                    "handWrittenArticle" &&
                                  `/news/${titleToPath(article.title)}`
                                }
                              />
                            </Box>
                          )
                        );
                      })}
                    </>
                  );
                }}
              </NewsContainer>
            </Box>
            <Box
              sx={{
                mr: [null, null, null, 1],
                mt: [3, null, null, 4],
                textAlign: ["center", null, null, "right"],
              }}
            >
              <ButtonLink isLink="/news/" variant="buttons.secondary">
                view all
              </ButtonLink>
            </Box>
          </Section>
        )}
      </>
    );
  };
  return (
    <HomeContainer>
      {(homePageData) => {
        const pageId = "home";
        const homeInfo = homePageData.find((data) => data.id === pageId);
        // const clientId = "client";
        // const clientInfo = clientHomeData.find((data) => data.id === clientId);

        return (
          <>
            <SEO pathname="home" templateTitle={homeInfo.pageTitle} />
            {setSection(homeInfo)}
          </>
        );
      }}
    </HomeContainer>
  );
};

HomePage.propTypes = {};

export default HomePage;
