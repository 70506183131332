import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";
import Box from "../../base/Box";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Carousel = ({ children, settings, sx }) => {
  const sliderStyled = {
    "&.slick-slider": {
      "& .slick-list": {
        flex: "1",
      },
      "& .slick-next, & .slick-prev": {
        height: "auto",
      },
      "& .slick-slide img": {
        display: "unset",
      },
      "& .slick-track": {
        flex: "none",
      },
      "&, & .slick-list, & .slick-slide, & .slick-slide > div": {
        flex: "auto",
        flexDirection: "column",
      },
      "&, & .slick-list, & .slick-track, & .slick-slide, & .slick-slide > div":
        {
          display: "flex",
          flexDirection: "row",
        },
      ...sx,
    },
  };

  const defaultSetting = {
    // dots: true,
    // infinite: false,
    // slidesToScroll: 1,
    // slidesToShow: 1,
    // speed: 500,
    ...settings,
  };
  return (
    <Box as={Slider} sx={sliderStyled} {...defaultSetting}>
      {children}
    </Box>
  );
};

Carousel.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({}),
  sx: PropTypes.shape({}),
};

Carousel.defaultProps = {
  settings: {},
  sx: {},
};

Carousel.defaultProps = {};

export default Carousel;
