import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const HomeContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allHomePageJson {
          edges {
            node {
              id
              pageTitle
              heroBannerSection {
                bannerImage
                heading
                content
                actionButton {
                  buttonLabel
                  buttonPath
                }
              }
              horizontalCardSection {
                hideSection
                heading
                content
                horizontalCard {
                  heading
                  content
                  cardImage
                }
              }
              hideNewsSection
            }
          }
        }
      }
    `}
    render={({
      allHomePageJson: { edges: homePageData },
      // allClientJson: { edges: clientHomeData },
    }) =>
      children(
        homePageData.map((e) => ({
          ...e.node,
        }))
        // clientHomeData.map((e) => ({
        //   ...e.node,
        // }))
      )
    }
  />
);

HomeContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default HomeContainer;
